// useApiUrl.js
import { useState, useEffect } from "react";

const useApiUrl = () => {
  const [apiUrl, setApiUrl] = useState("");

  useEffect(() => {
    // Construct the API URL based on current location
    const protocol = window.location.protocol;
    const domain = window.location.hostname;
    // const apiUrl = `${protocol}//${domain}`;
    const apiUrl = process.env.REACT_APP_API_URL;

    setApiUrl(apiUrl);
  }, []); // Empty dependency array ensures this runs only once

  return apiUrl; // Return the dynamically constructed URL
};

export default useApiUrl;
